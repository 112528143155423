import { graphql } from "gatsby";
import { createGatsbyPageComponent } from "@shapeable/ui";
// import { CommodityGroupLayout } from '../../components/entities/commodity-group-layout';
export default createGatsbyPageComponent('CommodityGroup', { });

export const query = graphql`
  query CommodityGroupsQuery($id: ID!) {
    platform {
      commodityGroup(id: $id) {
        __typename _schema { label pluralLabel }
        description { text }
        openGraph { title description { text(truncate: { words: 20 }) } image { url url2x thumbnails { card { url url2x } bubble { url url2x } } } }
        id slug path name
        
      }
    }
  }
  `;

